/** @jsxImportSource @emotion/react */

import { Button, notification, Skeleton } from "antd";
import { ReactComponent as DepositSvg } from "assets/Common/icons/Deposit.svg";
// import { ReactComponent as MemberDocumentIcon } from "assets/Common/icons/icon-member-documents.svg";
// import { ReactComponent as MemberVipIcon } from "assets/Common/icons/icon-member-vip.svg";
import { ReactComponent as ProfileActiveSvg } from "assets/Common/icons/profileActive.svg";
import { ReactComponent as WithdrawSvg } from "assets/Common/icons/withdraw.svg";
// import ReferralIcon from "assets/Desktop/icons/referral.png";
import { ReactComponent as BellIcon } from "assets/temporary/icons/bell.svg";
import { ReactComponent as BettingRecordIcon } from "assets/temporary/icons/betting-record.svg";
import { ReactComponent as HistoryIcon } from "assets/temporary/icons/history.svg";
import { ReactComponent as LogoutIcon } from "assets/temporary/icons/logout.svg";
// import { ReactComponent as MemberReportIcon } from "assets/temporary/icons/member-report.svg";
import { ReactComponent as RefreshIcon } from "assets/temporary/icons/refresh.svg";
import { useModalConfirm } from "components/Common/ModalConfirm";
// import { ReactComponent as SettingIcon } from "assets/temporary/icons/setting.svg";
import { PATH } from "contants/path";
import { formatNumberWithCommas } from "helpers/formatNumber";
import { useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useAppLogout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { selectUsername } from "store/auth";
import { selectBalance, selectProfile } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";
import { theme } from "styles/theme";
import { AppDispatch, StatusNotification } from "types";

import { UserMenuSidebarStyle } from "./index.style";

interface DataItemsMenuType {
  key: string;
  label: string;
  icon: React.ReactNode;
}
interface DataMenuType {
  data: DataItemsMenuType[];
  key: number;
}

const dataMenu: DataMenuType[] = [
  {
    data: [
      {
        key: PATH.User.deposit,
        label: "Deposit",
        icon: (
          <DepositSvg
            fill="none"
            className="history-icon"
            stroke={theme.colors.bank[10]}
          />
        ),
      },
      {
        key: PATH.User.withdraw,
        label: "Withdraw",
        icon: (
          <WithdrawSvg
            fill="none"
            className="history-icon"
            stroke={theme.colors.bank[10]}
          />
        ),
      },
      {
        key: PATH.User.TransactionHistory,
        label: "History",
        icon: (
          <HistoryIcon
            className="history-icon"
            stroke={theme.colors.bank[10]}
          />
        ),
      },
    ],
    key: 1,
  },
  {
    data: [
      {
        key: PATH.User.personalDetails,
        label: "Personal Information",
        icon: <ProfileActiveSvg fill={theme.colors.darkGreen} />,
      },
      // {
      //   key: PATH.User.myVip,
      //   label: "Member VIP",
      //   icon: <MemberVipIcon fill={theme.colors.darkGreen} />,
      // },
      // {
      //   key: PATH.User.MemberReport,
      //   label: (
      //     <span css={UserMenuSidebarStyle.menuLabel} data-comp="menu-label">
      //       Member Report
      //     </span>
      //   ),
      //   icon: <MemberReportIcon fill={theme.colors.darkGreen} />,
      // },
      {
        key: PATH.User.BettingRecord,
        label: "Betting History",
        icon: <BettingRecordIcon fill={theme.colors.darkGreen} />,
      },
      // {
      //   key: PATH.User.Referral,
      //   label: "Referral",
      //   icon: <img src={ReferralIcon} alt="" className="icon-image" />,
      // },
      {
        key: PATH.User.Announcement,
        label: "Messages",
        icon: <BellIcon fill="#096FE8" />,
      },
      // {
      //   key: PATH.User.Setting,
      //   label: "Setting",
      //   icon: <SettingIcon width={19} height={19} fill="#096FE8" />,
      // },
    ],
    key: 2,
  },
  // {
  //   title: "KYC Certificate",
  //   data: [
  //     {
  //       key: PATH.User.memberDocuments,
  //       label: "Upload",
  //       icon: <MemberDocumentIcon fill={theme.colors.darkGreen} />,
  //     },
  //   ],
  //   key: 3,
  // },
];

const UserMenuSidebar = () => {
  const { t } = useTranslation(["menuUser", "currency", "login"]);
  const [api, contextHolder] = notification.useNotification();
  const { contextHolder: ContextConfirm, modalConfig } = useModalConfirm();

  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onLogout = useAppLogout();

  const balance = useSelector(selectBalance);
  const profile = useAppSelector(selectProfile);

  const _username =
    useSelector(selectUsername) || localStorage.getItem("username") || "";

  const [isLoading, setIsLoading] = useState(false);

  // const dataEmptyBalance = _isEqual(initialState.balance, balance);

  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  const handleSelected = (key: string) => {
    navigate(key);
  };

  const showConfirmLogout = () => {
    modalConfig({
      title: t("login:Logout"),
      onSuccess: () => {
        onLogout();
      },
    });
  };

  // useEffect(() => {
  //   if (!dataEmptyBalance) return;

  //   dispatch(getMemberBalance({ username: _username, provider: "" }));
  // }, []);

  const renderMenu = dataMenu.map((item, index) => {
    return (
      <div css={UserMenuSidebarStyle.menuBoxWrap} key={`key-${index}-menu`}>
        {/* <div css={UserMenuSidebarStyle.menuTitle}>{t(item.title)}</div> */}
        {item.data.map((itemMenu: DataItemsMenuType) => {
          return (
            <div
              key={itemMenu.key}
              onClick={() => handleSelected(itemMenu.key)}
              css={UserMenuSidebarStyle.menuItem(itemMenu.key === pathname)}
            >
              {itemMenu.icon}
              <span css={UserMenuSidebarStyle.menuLabel} data-comp="menu-label">
                {t(itemMenu.label)}
              </span>
            </div>
          );
        })}
      </div>
    );
  });

  const handleRefreshBalance = async () => {
    try {
      setIsLoading(true);
      await dispatch(getMemberBalance({ username: _username }));
    } catch (error) {
      openNotification("error", "Please try again later!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div css={UserMenuSidebarStyle.self}>
      <div css={UserMenuSidebarStyle.menuBox}>
        <div css={UserMenuSidebarStyle.wallet}>
          <span css={UserMenuSidebarStyle.walletUsername}>
            {profile?.username}
          </span>
          <div css={UserMenuSidebarStyle.flex}>
            <span css={UserMenuSidebarStyle.walletValue}>
              <Skeleton
                loading={isLoading}
                style={{ height: "100%", width: "60px" }}
                title={isLoading}
                active={isLoading}
                paragraph={false}
              >
                <p>
                  {t("currency:HKD")}{" "}
                  {formatNumberWithCommas(balance.total || "0.00")}
                </p>
              </Skeleton>
            </span>
            <div
              css={UserMenuSidebarStyle.skeleton(isLoading)}
              onClick={handleRefreshBalance}
            >
              <RefreshIcon height={14} width={14} />
            </div>
          </div>
        </div>
        {renderMenu}
      </div>
      {/* <div css={UserMenuSidebarStyle.menu2}>
        {itemsBanking.slice(3, 5).map((item, index) => {
          return (
            <div
              key={`item-2-${index}`}
              onClick={() => handleSelected(item.key)}
              css={UserMenuSidebarStyle.activeMenu(item.key === pathname)}
            >
              {item.icon}
              {item.label}
            </div>
          );
        })}
      </div> */}
      <div css={UserMenuSidebarStyle.buttonContainer}>
        <Button
          size="middle"
          type="primary"
          css={UserMenuSidebarStyle.btnLogout}
          icon={<LogoutIcon style={{ marginTop: "-2px" }} />}
          onClick={showConfirmLogout}
          block
        >
          {t("button:Logout")}
        </Button>
      </div>
      {contextHolder}
      {ContextConfirm}
    </div>
  );
};

export default UserMenuSidebar;
