export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent
);

export const FORMAT_DATE = "DD/MM/YYYY hh:mm";

export const DOMAIN = "";

export const URL_DOWNLOAD_ANDROID = "";

export const IMAGE_LINK = {
  uploadBank: (image: string) => `https://easy88.club/uploads/banks/${image}`,
};

export const HEIGHT = {
  FOOTER: "240px",
  HEADER: "75px",
};

export const sysLangLocal = "syslang";

export const GATEWAY = {
  DAXIANGPAY: "DAXIANGPAY",
};
