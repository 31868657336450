import "css/index.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./i18n";

import { ThemeProvider } from "@emotion/react";
import { AuthApi } from "api/auth";
import { Modal } from "components/Common/Modal";
import BackToTop from "components/Desktop/ui/BackToTop";
import Message from "components/Desktop/ui/Message";
import { TOKEN, USERNAME } from "contants";
import { isMobile } from "contants/common";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useAppLogout";
import { useIP } from "hooks/useIP";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router";
import {
  selectIsAuthenticated,
  selectToken,
  selectUsername,
  updateIsAuthenticated,
} from "store/auth";
import { selectNameLoginGame, updateNameLoginGame } from "store/game";
import { getMemberBalance, getProfile } from "store/user/user.thunk";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";

function App() {
  useIP();

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const token = localStorage.getItem(TOKEN);
  const username = localStorage.getItem(USERNAME);

  const tokenRedux = useAppSelector(selectToken);

  const userNameRedux = useAppSelector(selectUsername);
  const nameLoginGame = useAppSelector(selectNameLoginGame);

  const onLogout = useAppLogout();

  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const fetchData = async (username: string, token: string) => {
    try {
      const response = await AuthApi.verifyToken(username, {
        username: username,
        token: token,
      });

      if (response.status === ResponseCode.SUCCESS) {
        await dispatch(getProfile(username));
        dispatch(updateIsAuthenticated(true));
        await dispatch(getMemberBalance({ username }));
      } else {
        onLogout();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!username) return;
    if (!token) return;

    fetchData(username, token);
  }, []);

  useEffect(() => {
    if (!username) return;
    if (!token) return;

    dispatch(getMemberBalance({ username }));
  }, [username]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isAuth) {
      handleInteraction(nameLoginGame);
      interval = setInterval(() => {
        fetchData(userNameRedux, tokenRedux);
      }, 1000 * 30);
    } else {
      if (interval) clearInterval(interval);
      if (appRef.current) clearTimeout(appRef.current);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(appRef.current);
    };
  }, [isAuth]);

  const handlePageFocus = () => {
    dispatch(updateNameLoginGame(""));
  };

  const handlePageBlur = () => {
    // console.log("");
  };

  useEffect(() => {
    window.addEventListener("focus", handlePageFocus);
    window.addEventListener("blur", handlePageBlur);

    return () => {
      window.removeEventListener("focus", handlePageFocus);
      window.removeEventListener("blur", handlePageBlur);
    };
  }, []);

  useEffect(() => {
    if (nameLoginGame === "LoginGame") {
      clearTimeout(appRef.current);
    }
  }, [nameLoginGame]);

  const handleInteraction = (nameGame: string) => {
    if (appRef.current) {
      clearTimeout(appRef.current);
    }

    appRef.current = setTimeout(() => {
      onLogout();
    }, 1000 * 60 * 10);
  };

  return (
    <div
      onMouseEnter={() => handleInteraction(nameLoginGame)}
      onKeyDown={() => handleInteraction(nameLoginGame)}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Outlet />
        <Modal />
        {!isMobile && <BackToTop />}
        <Message />
      </ThemeProvider>
    </div>
  );
}

export default App;
