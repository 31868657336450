import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { GameApi } from "api/game";
import ImageLogo from "assets/Common/icons/Easy88.png";
import axios, { CancelTokenSource } from "axios";
import { ResponseCode } from "contants/response";
import { updateNameLoginGame } from "store/game";
import { setOpenModal } from "store/modal";
import { StatusNotification } from "types";
import { SubGameType } from "types/game";

let openGames: Window[] = [];

export const handleLoginGame = async (
  dataLogin: SubGameType,
  username: string,
  dispatch: Dispatch<AnyAction>,
  openNotification: (status: StatusNotification, message: string) => void
) => {
  if (!username) {
    dispatch(setOpenModal("signin"));
    return;
  }

  if (openGames.length > 0) {
    openGames.forEach((win) => {
      win.close();
    });
    openGames = [];
  }

  const source: CancelTokenSource = axios.CancelToken.source();

  const openBlank = window.open("", "LoginGame");

  if (!openBlank) return;

  // const loaderElement = openBlank.document.createElement("div");
  // const style = openBlank.document.createElement("style");

  // loaderElement.className = "loader";

  // style.textContent = `
  //   .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:#333333;}
  //   .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#BD161D;border-radius:50%;animation:loading 2s ease infinite}
  //   @keyframes loading {
  //     from { transform: rotate(0turn)}
  //     to { transform:rotate(1turn)}
  //   }
  // `;

  dispatch(updateNameLoginGame(openBlank.name));
  // openBlank.document.body.appendChild(loaderElement);
  // openBlank.document.head.appendChild(style);

  openBlank.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Loading...</title>
        <style>
          .loader { height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; background: #f5f8fd; z-index: 999999 }
          .loader-image { width: 16rem; object-fit: contain; animation: scaleImage 2s ease infinite }
          @keyframes scaleImage {
            0% { transform: scale(1) }
            50% { transform: scale(1.2) }
            100% { transform: scale(1) }
          }
        </style>
      </head>
      <body>
        <div class="loader">
          <img class="loader-image" src="${ImageLogo}" alt="Loading..." />
        </div>
      </body>
    </html>
  `);

  openGames.push(openBlank);

  try {
    const response = await GameApi.loginGame(username, {
      username,
      game_type: dataLogin.type,
      game_code: dataLogin.game_code,
      provider: dataLogin.provider_code,
      source,
    });

    const dataLoginGame = response.data;

    if (dataLoginGame.status !== ResponseCode.SUCCESS) {
      openNotification(
        "error",
        "The game is under maintenance, please try again later!"
      );
      openBlank?.close();
      dispatch(updateNameLoginGame(""));
      return;
    }

    openNotification("success", "Login game is success!");

    (openBlank?.location as { href: string }).href = dataLoginGame.url;
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }
    openNotification(
      "error",
      "Server is under maintenance, please try again later!"
    );
    dispatch(updateNameLoginGame(""));
  }
};
